<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <v-container>
          <v-btn
            color="error"
            dark
            class="pl-1"
            style="float: right;"
            @click="onClose"
          >
            <v-icon class="mr-2">mdi-close</v-icon>
            <span style="text-transform: none;">{{
              $t('common.close')
            }}</span>
          </v-btn>
          <tab-header
            :tab-items="mainTabItems"
            @onSelectTab="onChangedTab" />
          <v-container
            v-if="mainTabSelected == mainTabIdx.INFO"
            grid-list-xl>
            <material-card
              :title="$t('workpieceDetail.voucherInfo')"
              color="green"
              flat
              full-width
            >
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                <v-layout
                  wrap
                  layout>
                  <v-flex
                    v-if="(workpiece.type != VoucherType.PROMOTION)"
                    xs12
                    sm12
                    md12>
                    <span class="font-weight-bold"><span class="red--text">*</span> {{ workpiece.isTransferable != null && workpiece.isTransferable ? $t('evoucher.issuance.transferable') : $t('evoucher.issuance.notTransferable') }}</span>
                  </v-flex>
                  <v-flex
                    v-if="(workpiece.type != VoucherType.PROMOTION)"
                    xs12
                    sm12
                    md12>
                    <v-checkbox
                      v-if="canUpdate()"
                      v-model="workpiece.isPersistentCode"
                      :label="$t('workpiece.persistentActiveCode')"
                      style="margin: 0; padding: 0;"
                    />
                    <span
                      v-else
                      class="font-weight-bold"><span class="red--text">*</span> {{ $t('workpiece.persistentActiveCode') }}</span>
                  </v-flex>
                  <v-flex
                    v-if="workpiece.type === VoucherType.VOUCHER"
                    xs12
                    sm12
                    md12>
                    <v-checkbox
                      v-model="workpiece.isHidePrice"
                      :label="$t('workpiece.hidePrice')"
                      :readonly="!canUpdate()"
                      style="margin: 0; padding: 0;"
                    />
                  </v-flex>
                </v-layout>
                <v-layout
                  wrap
                  layout>
                  <!-- Name -->
                  <v-flex
                    xs12
                    sm3
                    md3>
                    <v-text-field
                      :rules="[ruleRequiredValue]"
                      v-model="workpiece.name"
                      :label="$t('actions.name')"
                      :name="$t('actions.name')"
                      :readonly="!canUpdate()"
                      type="text"
                      class="required"
                    />
                  </v-flex>
                  <!-- Quantity -->
                  <v-flex
                    xs12
                    sm3
                    md3>
                    <v-text-field
                      :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                      v-model="workpiece.quantity"
                      :label="$t('evoucher.issuance.quantity')"
                      :name="$t('evoucher.issuance.quantity')"
                      readonly
                      type="number"
                      class="required"
                    />
                  </v-flex>
                  <!-- Status -->
                  <!-- <v-flex
                    xs12
                    sm4
                    md4>
                    <v-select
                      v-model="workpiece.status"
                      :items="workpiece.statusList"
                      :label="$t('evoucher.issuance.status')"
                      :disabled="!canUpdate()"
                    >
                      <template
                        slot="selection"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                      <template
                        slot="item"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                    </v-select>
                  </v-flex> -->
                  <!-- Type -->
                  <v-flex
                    xs12
                    sm3
                    md3>
                    <v-select
                      v-model="workpiece.type"
                      :items="workpiece.valueTypeList"
                      :label="$t('evoucher.issuance.type')"
                      readonly
                      @change="voucherIssuanceTypeChange"
                    >
                      <template
                        slot="selection"
                        slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ $t(data.item.text) }}
                      </template>
                      <template
                        slot="item"
                        slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ $t(data.item.text) }}
                      </template>
                    </v-select>
                  </v-flex>
                  <!-- Value -->
                  <v-flex
                    xs12
                    sm3
                    md3>
                    <v-text-field
                      :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                      v-model="workpiece.valueFormatter"
                      :label="workpiece.type == VoucherType.COUPON ? $t('evoucher.issuance.percent') : $t('evoucher.issuance.price')"
                      :name="workpiece.type == VoucherType.COUPON ? $t('evoucher.issuance.percent') : $t('evoucher.issuance.price')"
                      :readonly="(workpiece.type == VoucherType.PROMOTION) || !canUpdate()"
                      class="required"
                      @input="formatValue"
                    />
                  </v-flex>
                </v-layout>
                <v-layout
                  wrap
                  layout>
                  <!-- Promotion Type -->
                  <v-flex
                    v-if="workpiece.type == VoucherType.PROMOTION"
                    xs12
                    sm3
                    md3>
                    <v-select
                      :rules="[ruleRequiredValue]"
                      v-model="workpiece.promotionType"
                      :items="promotionTypeList"
                      :label="$t('promotionVoucher.type')"
                      class="required"
                      readonly
                      @change="voucherIssuanceTypeChange"
                    >
                      <template
                        slot="selection"
                        slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ $t(data.item.text) }}
                      </template>
                      <template
                        slot="item"
                        slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ $t(data.item.text) }}
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                  >
                    <v-text-field
                      v-if="workpiece.type == VoucherType.PROMOTION"
                      :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                      v-model="workpiece.numOfUsesPerPerson"
                      :label="$t('evoucher.issuance.numOfUsesPerPerson')"
                      :name="$t('evoucher.issuance.numOfUsesPerPerson')"
                      type="number"
                      class="required"
                      readonly
                    />
                  </v-flex>
                  <!-- From -->
                  <!-- <v-flex
                    v-if="workpiece.type == VoucherType.PROMOTION"
                    xs12
                    sm2
                    md2>
                    <v-menu
                      ref="menuEndDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      lazy
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :rules="[ruleRequiredValue]"
                          v-model="startDateFormatted"
                          :label="$t('createVoucher.fromDate')"
                          persistent-hint
                          class="required"
                          @blur="date = parseDate(startDateFormatted)"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        :min="minStartDate"
                        no-title
                        @input="menuStartDate = false"
                      />
                    </v-menu>
                  </v-flex> -->
                  <!-- To -->
                  <!-- <v-flex
                    v-if="workpiece.type == VoucherType.PROMOTION"
                    xs12
                    sm2
                    md2>
                    <v-menu
                      ref="menuEndDate"
                      v-model="menuExpiredDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      lazy
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :rules="[ruleRequiredValue]"
                          v-model="expiredDateFormatted"
                          :label="$t('createVoucher.toDate')"
                          persistent-hint
                          class="required"
                          @blur="date = parseDate(expiredDateFormatted)"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="expiredDate"
                        :min="minExpiredDate"
                        no-title
                        @input="menuExpiredDate = false"
                      />
                    </v-menu>
                  </v-flex> -->
                  <!-- Number of uses per person -->
                  <!-- <v-flex
                    v-if="(workpiece.type == VoucherType.COUPON) && !forConasi"
                    xs12
                    sm12
                    md12>
                    <span><span style="color: red; font-weight: bold;">* </span>{{ $t('evoucher.issuance.usedValidWithTypePercent') }}</span>
                  </v-flex> -->
                  <!-- Min Value -->
                  <!-- <v-flex
                    v-if="(workpiece.type == VoucherType.COUPON) && !forConasi"
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                      v-model="workpiece.minValueFormatter"
                      :label="$t('evoucher.issuance.fromValue')"
                      :name="$t('evoucher.issuance.fromValue')"
                      class="required"
                      @input="formatMinValue"
                    />
                  </v-flex> -->
                  <!-- Max Value -->
                  <!-- <v-flex
                    v-if="(workpiece.type == VoucherType.COUPON) && !forConasi"
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      :rules="[ruleRequiredValue, ruleMustGreaterThanMinValue]"
                      v-model="workpiece.maxValueFormatter"
                      :label="$t('evoucher.issuance.toValue')"
                      :name="$t('evoucher.issuance.toValue')"
                      class="required"
                      @input="formatMaxValue"
                    />
                  </v-flex> -->
                </v-layout>
                <v-layout
                  wrap
                  layout>
                  <!-- User in promotion -->
                  <!-- <v-flex
                    v-if="workpiece.type == VoucherType.PROMOTION"
                    xs12
                    sm6
                    md6>
                    <v-autocomplete
                      v-model="customer.ids"
                      :items="customer.list"
                      :clearable="true"
                      :label="$t('Khách hàng được nhận')"
                      :readonly="!canUpdate()"
                      multiple
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 4">
                          <span>{{ $t(item.text) }}</span>
                        </v-chip>
                        <span
                          v-if="index === 4"
                          class="grey--text caption">(+{{ customer.ids.length - 4 }} {{ $t('voucherReport.others') }})</span>
                      </template>
                    </v-autocomplete>
                  </v-flex> -->
                  <!-- Select hashtag for voucher promotion allocated -->
                  <!-- <v-flex
                    v-if="workpiece.type == VoucherType.PROMOTION && workpiece.promotionType == promotionType.ALLOCATED"
                    xs12
                    sm12
                    md12>
                    <v-autocomplete
                      :rules="[ruleRequiredValue]"
                      v-model="hashtagSelecteds"
                      :items="hashtagList"
                      :clearable="true"
                      :label="$t('hashtag.customerGroupsAreReceived')"
                      multiple
                      class="required"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 4">
                          <span>{{ $t(item.text) }}</span>
                        </v-chip>
                        <span
                          v-if="index === 4"
                          class="grey--text caption">(+{{ hashtagSelecteds.length - 4 }} {{ $t('voucherReport.others') }})</span>
                      </template>
                      <template v-slot:item="{ item }">
                        {{ $t(item.text) }} [{{ item.count }}]
                      </template>
                    </v-autocomplete>
                  </v-flex> -->
                </v-layout>
                <v-layout
                  wrap
                  layout>
                  <v-flex
                    xs12
                    sm12
                    md12>
                    <v-layout
                      wrap
                      layout>
                      <!-- Description -->
                      <v-flex
                        xs12
                        sm6
                        md6>
                        <v-textarea
                          v-model="workpiece.description"
                          :label="$t('evoucher.issuance.voucherDescription')"
                          :name="$t('evoucher.issuance.voucherDescription')"
                          :readonly="!canUpdate()"
                          type="text"
                          rows="4"
                        />
                      </v-flex>
                      <!-- Conditions of use -->
                      <v-flex
                        xs12
                        sm6
                        md6>
                        <v-textarea
                          v-model="workpiece.conditionsOfUse"
                          :label="$t('evoucher.issuance.conditionsOfUse')"
                          :name="$t('evoucher.issuance.conditionsOfUse')"
                          :readonly="!canUpdate()"
                          type="text"
                          rows="4"
                        />
                      </v-flex>
                      <!-- Details info for printing -->
                      <v-flex
                        v-if="forConasi && (workpiece.type != VoucherType.PROMOTION) && (isSupplier() || isAdmin())"
                        xs12
                        sm12
                        md12>
                        <v-textarea
                          v-model="workpiece.details"
                          :label="$t('evoucher.issuance.details')"
                          :name="$t('evoucher.issuance.details')"
                          :readonly="!canUpdate()"
                          type="text"
                          rows="5"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!-- Usage site -->
                  <v-flex
                    v-if="hasAction('update_issuance') && !isAdmin() && !isEntityDisabled()"
                    xs12
                    sm12
                    md12>
                    <v-autocomplete
                      v-model="usage_site.ids"
                      :items="usage_site.list"
                      :clearable="true"
                      :label="$t('siteManagement.chooseSite')"
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-tile
                          ripple
                          @click="toggleAllSites"
                        >
                          <v-list-tile-action>
                            <v-icon :color="usage_site.ids.length > 0 ? 'primary darken-4' : ''">
                              {{ allSiteCheckIcon }}
                            </v-icon>
                          </v-list-tile-action>
                          <v-list-tile-content>
                            <v-list-tile-title>
                              {{ $t('common.selectAll') }}
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-divider class="mt-2"/>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 4">
                          <span>{{ $t(item.text) }}</span>
                        </v-chip>
                        <span
                          v-if="index === 4"
                          class="grey--text caption">(+{{ usage_site.ids.length - 4 }} {{ $t('voucherReport.others') }})</span>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex
                    v-else
                    xs12
                    sm12
                    md12>
                    <div>
                      <span class="d-block font-weight-bold">{{ $t('evoucher.detail.usageSite') }}: </span>
                      <div v-if="workpiece.usageSites.length > 0">
                        <div
                          v-for="(site, index) in workpiece.usageSites"
                          :key="site + index"
                          class="font-medium mt-2">
                          <span class="d-block font-weight-bold ml-2">{{ index + 1 + '. ' + site.name }}</span>
                          <div v-if="site.fullAddress">
                            <address-label
                              :longitude="site.latitude"
                              :latitude="site.longitude">
                              {{ site.fullAddress }}
                            </address-label>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <span class="d-block ml-2">{{ $t('evoucher.issuance.noSelectedSite') }}</span>
                      </div>
                    </div>
                  </v-flex>
                  <!-- Issuance img -->
                  <v-flex
                    class="text-center"
                    xs12
                    md6
                  >
                    <image-picker
                      key="issuance-img"
                      :image-width="300"
                      :image-height="200"
                      :image-url="issuanceImage"
                      :readonly="!canUpdate()"
                      exceptation-ratio="3:2"
                      max-size-upload="3MB"
                      recommend-dimension="300x200"
                      @onSelectedImageFile="onSelectIssuanceImg"
                      @onResetImage="onClickRemoveIssuanceImg"
                    />
                  </v-flex>
                  <v-flex
                    v-if="forConasi && !isAccountant() && (workpiece.type != VoucherType.PROMOTION)"
                    xs12
                    md6
                  >
                    <v-autocomplete
                      v-model="workpiece.printTemplateId"
                      :items="printTemplateList"
                      :clearable="true"
                      :label="$t('printVoucher.selectPrintTemplate')"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip>
                          <span>{{ $t(item.text) }}</span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-form>
            </material-card>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation>
              <v-layout
                wrap
                layout>
                <!-- Button create voucher -->
                <v-flex
                  v-if="forConasi && quantityVoucherList.length > 0 && quantityVoucherList[0].quantityNotCreated > 0 && hasAction('create_voucher') && !isAccountant() && isSupplier() && !isAdmin() && !isEntityDisabled()"
                  xs6
                  sm2
                  md2>
                  <v-tooltip
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        style="color: #ffffff; margin: 0; width: 100%;"
                        v-on="on"
                        @click="onShowModalAddEVoucher(createVoucherType.LIST)"
                      >
                        <v-icon class="mr-2">mdi-playlist-plus</v-icon>
                        <span style="text-transform: none;">{{ $t('actionPermission.create_voucher') }}</span>
                      </v-btn>
                    </template>
                    <span>{{ $t("actionPermission.create_voucher") }}</span>
                  </v-tooltip>
                </v-flex>
                <!-- Distribute voucher -->
                <!-- <v-flex
                  v-if="forConasi && quantityVoucherList.length > 0 && quantityVoucherList[0].quantityNotDistributed > 0 && hasAction('delivery_voucher') && !isAdmin()"
                  xs6
                  sm3
                  md3>
                  <v-tooltip
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        style="color: #ffffff; margin: 0; width: 100%"
                        v-on="on"
                        @click="onShowModalAssignVoucher()"
                      >
                        <v-icon class="mr-2">mdi-transfer</v-icon>
                        <span style="text-transform: none;">{{ $t("evoucher.assignVoucher") }}</span>
                      </v-btn>
                    </template>
                    <span>{{ $t("evoucher.assignVoucher") }}</span>
                  </v-tooltip>
                </v-flex> -->
                <!-- Save info voucher -->
                <v-flex
                  v-if="!isAccountant() && (hasAction('update_issuance') && !isEntityDisabled() || isAdmin())"
                  xs6
                  sm2
                  md2>
                  <v-tooltip
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        style="color: #ffffff; margin: 0; width: 100%;"
                        v-on="on"
                        @click="onConfirm"
                      >
                        <v-icon class="mr-2">mdi-check</v-icon>
                        <span style="text-transform: none;">{{ $t("common.save") }}</span>
                      </v-btn>
                    </template>
                    <span>{{ $t("common.save") }}</span>
                  </v-tooltip>
                </v-flex>
                <v-flex
                  v-if="!forConasi && !isAccountant() && (workpiece.status === voucherIssuanceStatusType.WAIT_FOR_APPROVAL) && !isEntityDisabled()"
                  xs6
                  sm2
                  md2>
                  <v-tooltip
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="info darken-1"
                        style="color: #ffffff; margin: 0; width: 100%;"
                        v-on="on"
                        @click="onShowModalApprovalIssuance"
                      >{{ $t('common.approval') }}
                      </v-btn>
                    </template>
                    <span>{{ $t('common.approval') }}</span>
                  </v-tooltip>
                </v-flex>
                <!-- Button request voucher -->
                <v-flex
                  v-if="!isAccountant() && isEntityType(entityType.AGENT)"
                  xs6
                  sm2
                  md2>
                  <v-tooltip
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        style="color: #ffffff; margin: 0; width: 100%;"
                        v-on="on"
                        @click="requestEVoucherToParent($event)"
                      >
                        <v-icon
                          size="18"
                          class="mr-2">mdi-message-bulleted</v-icon>
                        <span style="text-transform: none;">{{ $t("issueVoucherRequest.requestTitle") }}</span>
                      </v-btn>
                    </template>
                    <span>{{ $t("issueVoucherRequest.requestTitle") }}</span>
                  </v-tooltip>
                </v-flex>
                <v-flex
                  v-if="forConasi && !isAccountant() && (workpiece.type != VoucherType.PROMOTION)"
                  xs6
                  sm2
                  md2>
                  <v-tooltip
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="warning darken-1"
                        style="color: #ffffff; margin: 0;"
                        v-on="on"
                        @click="onPreviewPrintVoucher"
                      >{{ $t('evoucher.issuance.previewPrint') }}
                      </v-btn>
                    </template>
                    <span>{{ $t("evoucher.issuance.previewPrint") }}</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
          <v-container v-if="forConasi && mainTabSelected == mainTabIdx.WORKPIECE">
            <material-card
              :title="$t('evoucher.issuance.title')"
              color="green"
              flat
              full-width
            >
              <v-data-table
                :no-data-text="$t('common.noDataAvailable')"
                :headers="workpieceHeaders"
                :items="workpieceList"
                hide-actions
              >
                <template
                  slot="headerCell"
                  slot-scope="{ header }"
                >
                  <span
                    class="text--darken-3 font-medium"
                    v-text="$t(header.text)"
                  />
                </template>
                <template
                  v-slot:items="props"
                >
                  <td
                    v-for="(header, index) in workpieceHeaders"
                    :key="header.value + index"
                    class="text-xs-center">
                    <span
                      v-if="header.value == 'actions'"
                      style="display: flex;">
                      <!-- Distribute voucher -->
                      <v-tooltip
                        top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-if="isSupplier()"
                            class="mx-2"
                            fab
                            small
                            color="purple"
                            style="color: #ffffff;"
                            v-on="on"
                            @click="onShowModalAssignVoucherWithWorkpiece(props.item)"
                          >
                            <v-icon>mdi-transfer</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("evoucher.assignVoucher") }}</span>
                      </v-tooltip>
                      <!-- Extern expiration date -->
                      <v-tooltip
                        top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-if="isSupplier()"
                            class="mx-2"
                            fab
                            small
                            color="warning"
                            style="color: #ffffff;"
                            v-on="on"
                            @click="onShowModalExternExpiredDays($event, props.item.issuanceId)"
                          >
                            <v-icon>mdi-calendar-multiple</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("evoucher.issuance.extendExpiredDate") }}</span>
                      </v-tooltip>
                      <!-- History voucher -->
                      <v-tooltip
                        top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            color="success"
                            style="color: #ffffff;"
                            v-on="on"
                            @click="onViewHistoryTransaction(props.item)"
                          >
                            <v-icon>mdi-history</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('evoucher.history') }}</span>
                      </v-tooltip>
                    </span>
                    <div
                      v-else-if="header.value === 'remainQuantityInAgency'">
                      {{ props.item.quantityAssignedToAgent - props.item.quantityAssignedToCustomer }}
                    </div>
                    <span v-else>{{ props.item[header.value] }}</span>
                  </td>
                </template>
              </v-data-table>
              <pagination
                :total="workpieceListPaginate.totalPage"
                :current-page="workpieceListPaginate.currentPage"
                :row-per-page="workpieceListPaginate.rowPerPage"
                @onPageChange="onWorkpieceListPageChange"
              />
            </material-card>
          </v-container>
          <v-container v-if="mainTabSelected == mainTabIdx.HISTORY">
            <!-- Quantity voucher -->
            <material-card
              :title="$t('workpieceDetail.voucherQuantity')"
              color="green"
              flat
              full-width
            >
              <v-data-table
                :no-data-text="$t('common.noDataAvailable')"
                :headers="quantityVoucherHeaders"
                :items="quantityVoucherList"
                :single-select="true"
                hide-actions
              >
                <template
                  slot="headerCell"
                  slot-scope="{ header }">
                  <span
                    class="text--darken-3 font-medium"
                    v-text="$t(header.text)"
                  />
                </template>
                <template
                  slot="items"
                  slot-scope="{ item }">
                  <td
                    v-for="(header, index) in quantityVoucherHeaders"
                    :key="header.value + index"
                    class="text-xs-center"
                  >
                    <span>{{ item[header.value] }}</span>
                  </td>
                </template>
              </v-data-table>
            </material-card>
            <!-- Price voucher -->
            <material-card
              :title="$t('workpieceDetail.price')"
              color="green"
              flat
              full-width>
              <v-data-table
                :no-data-text="$t('common.noDataAvailable')"
                :headers="voucherPriceHeaders"
                :items="voucherPriceList"
                :single-select="true"
                hide-actions
              >
                <template
                  slot="headerCell"
                  slot-scope="{ header }">
                  <span
                    class="text--darken-3 font-medium"
                    v-text="$t(header.text)"
                  />
                </template>
                <template
                  slot="items"
                  slot-scope="{ item }">
                  <td
                    v-for="(header, index) in voucherPriceHeaders"
                    :key="header.value + index"
                    class="text-xs-center"
                  >
                    <span>{{ item[header.value] }}</span>
                  </td>
                </template>
              </v-data-table>
            </material-card>
            <!-- Voucher information distributed to the agent -->
            <material-card
              :title="$t('workpieceDetail.voucherInfoDistributedToAgent')"
              color="green"
              flat
              full-width
            >
              <v-data-table
                :no-data-text="$t('common.noDataAvailable')"
                :headers="voucherInfoDistributedHeaders"
                :items="voucherInfoDistributedList"
                :single-select="true"
                hide-actions
              >
                <template
                  slot="headerCell"
                  slot-scope="{ header }">
                  <span
                    class="text--darken-3 font-medium"
                    v-text="$t(header.text)"
                  />
                </template>
                <template
                  slot="items"
                  slot-scope="{ item }">
                  <td
                    v-for="(header, index) in voucherInfoDistributedHeaders"
                    :key="header.value + index"
                    class="text-xs-center"
                  >
                    <div
                      v-if="header.value === 'nameCustomer'"
                      class="text-xs-left"
                    >
                      <div class="font-weight-bold">{{ item.nameCustomer }}</div>
                      <div v-if="item.customerEmail">({{ item.customerEmail }})</div>
                    </div>
                    <span v-else>{{ item[header.value] }}</span>
                  </td>
                </template>
              </v-data-table>
              <pagination
                :total="voucherInfoDistributedPaginate.totalPage"
                :current-page="voucherInfoDistributedPaginate.currentPage"
                :row-per-page="voucherInfoDistributedPaginate.rowPerPage"
                @onPageChange="onInfoDistributedPageChange"
              />
            </material-card>
          </v-container>
        </v-container>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <loading-bar :is-loading="isLoading" />
    <addEVoucherList
      ref="addEVoucherList"
      @createEVoucherSuccess="onCreateEVoucherSuccess()"
    />
    <!-- <assignVoucherModal
      ref="assignVoucherModal"
      @transactionVoucherSuccess="onTransactionVoucherSuccess()"
    /> -->
    <assignVoucherModal
      ref="assignVoucherModal"
      @transactionVoucherSuccess="onTransactionVoucherSuccess()"
      @onCreateEVoucher="onShowModalAddListEvoucher"
    />
    <requestEVoucherToParent ref="requestEVoucherToParent" />
    <workpieceHistoriesModal ref="workpieceHistoriesModal" />
    <extend-expired-day-modal ref="extendExpiredDayModal" />
    <previewPrintVoucher ref="previewPrintVoucher" />
    <confirm-modal
      ref="approvalIssuanceModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmApprovalIssuance"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { FOR_CONASI_TARGET, DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS } from 'utils/constants'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import stringUtils from 'utils/stringUtils'
import VoucherType from 'enum/voucherType'
import VoucherIssuanceStatusType from 'enum/voucherIssuanceStatusType'
import AddEVoucherList from './AddEvoucherList'
import CreateVoucherType from 'enum/createVoucherType'
import EntityRoleType from 'enum/entityRoleType'
import EntityType from 'enum/entityType'
import PromotionType from 'enum/promotionType'
import AssignVoucherModal from './AssignVoucherModal'
import RequestEVoucherToParent from './RequestEVoucherToParent'
import moment from 'moment'
import axios from 'axios'
import VoucherTransactionValue from 'enum/voucherTransactionValue'
import TabHeader from 'Components/TabHeader'
import WorkpieceHistoriesModal from './WorkpieceHistoriesModal'
import ExtendExpiredDayModal from 'Views/EVoucher/ExtendExpiredDayModal.vue'
import dateUtils from 'utils/dateUtils'
import PreviewPrintVoucher from 'Components/PreviewPrintVoucherModal'
import ConfirmModal from 'Components/ConfirmModal'
import AddressLabel from 'Components/AddressLabel.vue'
import ImagePicker from 'Components/ImagePicker.vue'

const TAB_ID = {
  INFO: 0,
  HISTORY: 1,
  WORKPIECE: 2
}

export default {
  components: {
    AddEVoucherList,
    AssignVoucherModal,
    RequestEVoucherToParent,
    WorkpieceHistoriesModal,
    ExtendExpiredDayModal,
    TabHeader,
    PreviewPrintVoucher,
    AddressLabel,
    ImagePicker,
    ConfirmModal
  },
  data () {
    return {
      valid: true,
      isShowModal: false,
      workpiece: {
        id: null,
        name: null,
        code: null,
        quantity: null,
        status: VoucherIssuanceStatusType.ACTIVATED,
        statusList: stringUtils.DistributionStatus,
        type: VoucherType.VOUCHER,
        typeList: stringUtils.VoucherTypeList,
        value: null,
        valueFormatter: null,
        valueTypeList: stringUtils.VoucherValueList,
        promotionType: null,
        description: null,
        details: null,
        conditionsOfUse: null,
        isTransferable: null,
        usageSites: [],
        hasDistributed: false,
        isPersistentCode: false,
        isHidePrice: false,
        ownerData: null,
        printTemplateId: null
      },
      indexRetrievingAddess: -1,
      promotionType: PromotionType,
      VoucherType: VoucherType,
      isLoading: false,
      workpieceHeaders: [
        {
          sortable: false,
          text: 'common.nonumber',
          value: 'stt',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.dateIssued',
          value: 'dateCreated',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.issuance.workpiece',
          value: 'issuanceCode',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpiece.name',
          value: 'workpiece',
          align: 'center'
        },
        {
          sortable: false,
          text: 'report.quantityVoucherSupplierToAgent.issueNumber',
          value: 'quantityAssigned',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpiece.unreleasedQuantity',
          value: 'quantityRemain',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpiece.quantityDistributedAgent',
          value: 'quantityAssignedToAgent',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpiece.remainQuantityInAgency',
          value: 'remainQuantityInAgency',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpiece.quantityDistributedCustomer',
          value: 'quantityAssignedToCustomer',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.issuance.numberOfUsedVoucher',
          value: 'quantityUsedVouchers',
          align: 'center'
        },
        {
          sortable: false,
          text: 'common.actions',
          value: 'actions',
          align: 'center'
        }
      ],
      workpieceList: [],
      workpieceListPaginate: {
        currentPage: 1,
        totalPage: 1,
        rowPerPage: 1
      },
      voucherInfoDistributedHeaders: [
        {
          sortable: false,
          text: 'common.nonumber',
          value: 'stt',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpieceDetail.agentCode',
          value: 'agentCode',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpieceDetail.agentName',
          value: 'agentName',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.customer',
          value: 'nameCustomer',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.quantity',
          value: 'quantity',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.discount',
          value: 'discount',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpieceDetail.totalPrice',
          value: 'totalPrice',
          align: 'center'
        }
      ],
      voucherInfoDistributedList: [],
      voucherInfoDistributedPaginate: {
        totalPage: 1,
        currentPage: 1,
        rowPerPage: 1
      },
      quantityVoucherHeaders: [
        {
          sortable: false,
          text: 'workpieceDetail.total',
          value: 'total',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpieceDetail.quantityCreated',
          value: 'quantityCreated',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpieceDetail.quantityNotCreated',
          value: 'quantityNotCreated',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpieceDetail.quantityDistributed',
          value: 'quantityDistributed',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpieceDetail.quantityNotDistributed',
          value: 'quantityNotDistributed',
          align: 'center'
        }
      ],
      quantityVoucherList: [],
      voucherPriceHeaders: [
        {
          sortable: false,
          text: 'workpieceDetail.total',
          value: 'totalPrice',
          align: 'center'
        },
        {
          sortable: false,
          text: 'workpieceDetail.quantityDistributed',
          value: 'distributedPrice',
          align: 'center'
        },
        // {
        //   sortable: false,
        //   text: 'workpieceDetail.distributedAfterDiscountPrice',
        //   value: 'distributedAfterDiscountPrice',
        //   align: 'center'
        // },
        {
          sortable: false,
          text: 'workpieceDetail.quantityNotDistributed',
          value: 'notDistributedPrice',
          align: 'center'
        }
      ],
      promotionTypeList: stringUtils.PromotionTypeList,
      voucherPriceList: [],
      createVoucherType: CreateVoucherType,
      entityType: EntityType,
      usage_site: {
        list: [],
        ids: []
      },
      forConasi: FOR_CONASI_TARGET,
      customer: {
        list: [],
        ids: []
      },
      allTabItems: [
        {
          title: 'workpiece.issuanceInfo',
          id: TAB_ID.INFO,
          hidden: false
        },
        {
          title: 'workpiece.issuance',
          id: TAB_ID.WORKPIECE,
          hidden: !FOR_CONASI_TARGET
        },
        {
          title: 'workpiece.issuanceHistory',
          id: TAB_ID.HISTORY,
          hidden: false
        }
      ],
      mainTabItems: [
        {
          title: 'workpiece.issuanceInfo',
          id: TAB_ID.INFO,
          hidden: false
        }
      ],
      mainTabIdx: {
        INFO: TAB_ID.INFO,
        HISTORY: TAB_ID.HISTORY,
        WORKPIECE: TAB_ID.WORKPIECE
      },
      mainTabSelected: TAB_ID.INFO,
      media_server: {
        domain: '',
        token: ''
      },
      issuanceImage: null,
      imageFile: null,
      printTemplateList: [],
      confirmModalTitle: '',
      voucherIssuanceStatusType: VoucherIssuanceStatusType
    }
  },
  computed: {
    pickAllSites () {
      return this.usage_site.ids.length === this.usage_site.list.length
    },
    pickSomeSites () {
      return this.usage_site.ids.length > 0 && !this.pickAllSites
    },
    allSiteCheckIcon () {
      if (this.pickAllSites) return 'mdi-close-box'
      if (this.pickSomeSites) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    ...mapGetters([
      'GET_SNACK_BAR',
      'VOUCHER_ISSUANCE_DETAIL_DATA',
      'VOUCHER_INFO_DISTRIBUTED_TO_AGENT_DATA',
      'WORKPIECE_LIST_DETAIL_DATA',
      'GET_CURRENT_ACCOUNT',
      'CHILDREN_ENTITY_DATA',
      'CUSTOMER_LIST_DATA',
      'GET_TOKEN_MEDIA_SERVER_DATA'
    ])
  },
  watch: {
    VOUCHER_ISSUANCE_DETAIL_DATA () {
      let data = this.VOUCHER_ISSUANCE_DETAIL_DATA
      // Handle quantity voucher list
      this.quantityVoucherList = []
      this.quantityVoucherList.push({
        total: (this.isSupplier() || this.isAdmin()) ? data.statistics_quantity.total : data.statistics_quantity.distributed + data.statistics_quantity.not_distributed,
        quantityCreated: data.statistics_quantity.created,
        quantityNotCreated: data.statistics_quantity.not_created,
        quantityDistributed: data.statistics_quantity.distributed,
        quantityNotDistributed: data.statistics_quantity.not_distributed
      })
      this.workpiece.hasDistributed = data.statistics_quantity.distributed > 0
      // Handle workpiece info
      this.workpiece.name = data.name
      this.workpiece.quantity = (this.isSupplier() || this.isAdmin()) ? data.quantity : data.statistics_quantity.distributed + data.statistics_quantity.not_distributed
      this.workpiece.status = data.status
      this.workpiece.type = data.type
      this.workpiece.value = data.value
      this.workpiece.valueFormatter = functionUtils.convertFormatNumberToMoney(
        data.value
      )
      this.workpiece.promotionType = data.promotion_type
      this.workpiece.numOfUsesPerPerson = data.number_of_uses_per_person
      this.workpiece.description = data.description
      this.workpiece.details = data.details
      this.workpiece.conditionsOfUse = data.conditions_of_use
      this.workpiece.isTransferable = data.is_transferable
      this.workpiece.usageSites = []
      data.usage_sites.forEach((site) => {
        let item = {
          id: site.id,
          name: site.name,
          code: site.code,
          longitude: site.longitude,
          latitude: site.latitude,
          province: site.province,
          district: site.district,
          ward: site.ward,
          street: site.street,
          fullAddress: site.street
        }
        this.workpiece.usageSites.push(item)
      })
      this.usage_site.ids = data.usage_site_ids
      this.customer.ids = data.customer_ids
      this.issuanceImage = data.image_url
      // Handle price voucher
      this.voucherPriceList = []
      this.voucherPriceList.push({
        totalPrice: functionUtils.convertFormatNumberToMoney(
          ((this.isSupplier() || this.isAdmin()) ? data.statistics_quantity.total : data.statistics_quantity.distributed + data.statistics_quantity.not_distributed) * data.value
        ),
        distributedPrice: functionUtils.convertFormatNumberToMoney(
          data.statistics_quantity.distributed * data.value
        ),
        notDistributedPrice: functionUtils.convertFormatNumberToMoney(
          data.statistics_quantity.not_distributed * data.value
        ),
        distributedAfterDiscountPrice: 0
      })
      this.workpiece.isPersistentCode = data.is_persistent_code
      this.workpiece.isHidePrice = data.is_hide_price
      this.workpiece.ownerData = data.owner
      this.workpiece.printTemplateId = data.print_template_id
      this.indexRetrievingAddess = 0
      this.doRetrievingFullAddress()

      if (this.workpiece.type === VoucherType.PROMOTION) {
        this.mainTabItems = this.allTabItems.filter(element => {
          return element.id !== TAB_ID.HISTORY && element.id !== TAB_ID.WORKPIECE
        })
      } else {
        this.mainTabItems = this.allTabItems
      }
    },
    VOUCHER_INFO_DISTRIBUTED_TO_AGENT_DATA () {
      let res = this.VOUCHER_INFO_DISTRIBUTED_TO_AGENT_DATA
      // Handle paginate
      this.voucherInfoDistributedPaginate.currentPage = res.paginate.currentPage
      this.voucherInfoDistributedPaginate.totalPage = res.paginate.totalPage
      this.voucherInfoDistributedPaginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let voucherData = res.results
      this.voucherInfoDistributedList = []
      for (let i = 0, size = voucherData.length; i < size; i++) {
        this.voucherInfoDistributedList.push({
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          agentCode: voucherData[i].agent_code,
          agentName: voucherData[i].agent_name,
          totalPrice: functionUtils.convertFormatNumberToMoney(voucherData[i].total_price),
          quantity: voucherData[i].quantity,
          discount: this.getDiscountType(voucherData[i].discount, voucherData[i].discount_type),
          nameCustomer: voucherData[i].customer_fullname,
          customerEmail: voucherData[i].customer_email
        })
      }
    },
    WORKPIECE_LIST_DETAIL_DATA () {
      let res = this.WORKPIECE_LIST_DETAIL_DATA
      // Handle paginate
      this.workpieceListPaginate.currentPage = res.paginate.currentPage
      this.workpieceListPaginate.totalPage = res.paginate.totalPage
      this.workpieceListPaginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let workpieceData = res.results
      this.workpieceList = []
      for (let i = 0, size = workpieceData.length; i < size; i++) {
        let workpieceObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          issuanceCode: functionUtils.concatSuffixPrefix(workpieceData[i].issuance),
          dateCreated: dateUtils.formatBeautyDate(workpieceData[i].issuance.date_created),
          issuanceId: workpieceData[i].issuance.id,
          workpiece: workpieceData[i].workpiece.name,
          workpieceId: workpieceData[i].workpiece.id,
          quantityAssigned: workpieceData[i].quantity_distributed,
          quantityAssignedToAgent: workpieceData[i].quantity_distributed_agent,
          quantityAssignedToCustomer: workpieceData[i].quantity_distributed_customer,
          quantityRemain: workpieceData[i].quantity_not_distributed,
          quantityUsedVouchers: workpieceData[i].quantity_used_vouchers
        }
        this.workpieceList.push(workpieceObj)
      }
    },
    CHILDREN_ENTITY_DATA () {
      let data = this.CHILDREN_ENTITY_DATA
      this.usage_site.list = []
      for (let i = 0, size = data.length; i < size; i++) {
        let siteObj = {
          text: data[i].name,
          value: data[i].id
        }
        this.usage_site.list.push(siteObj)
      }
    },
    CUSTOMER_LIST_DATA () {
      let data = this.CUSTOMER_LIST_DATA.results
      this.customer.list = []
      for (let i = 0, size = data.length; i < size; i++) {
        let customerObj = {
          text: functionUtils.concatFullname(data[i].last_name, data[i].first_name) + ' - ' + data[i].email,
          value: data[i].id
        }
        this.customer.list.push(customerObj)
      }
    },
    GET_TOKEN_MEDIA_SERVER_DATA: function () {
      this.media_server.token = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token
      this.media_server.domain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain
    }
  },
  created () {
    let workpieceId = this.$route.params.id
    this.workpiece.id = workpieceId
    this.getEVoucherIssuanceDetail()
    this.getEVoucherInfoDistributedToAgent()
    if (!this.isSupplier()) {
      this.quantityVoucherHeaders = this.quantityVoucherHeaders.filter(element => {
        return element.value !== 'quantityCreated' && element.value !== 'quantityNotCreated'
      })
    }
    this.getChildrenEntity()
    this.getWorkpieceDetailList()
    this.GET_CUSTOMER_LIST()
    this.GET_TOKEN_MEDIA_SERVER()
    this.getTemplateConfigList()
  },
  mounted () {},
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    toggleAllSites () {
      this.$nextTick(() => {
        if (this.pickAllSites) {
          this.usage_site.ids = []
        } else {
          this.usage_site.ids = this.usage_site.list.slice()
        }
      })
    },
    /**
     * Get discount type
     */
    getDiscountType: function (discount, discountType) {
      // eslint-disable-next-line eqeqeq
      if (discountType == VoucherTransactionValue.PERCENT) {
        return discount && discount + '%'
      } else {
        return discount && discount + ' VND'
      }
    },
    onSelectIssuanceImg: function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.getResultFile(files[0])
      // this.productImgFileSize = files[0].size
      this.issuanceImage = URL.createObjectURL(files[0])
    },
    onClickRemoveIssuanceImg: function () {
      this.issuanceImage = null
      // this.productImgFileSize = null
    },
    getResultFile: function (file) {
      let vm = this
      vm.imageFile = file
    },
    /**
     * Get children entity
     */
    getChildrenEntity: function () {
      let filter = {
        params: {
          type: EntityType.SITE
        }
      }
      this.GET_CHILDREN_ENTITY(filter)
    },
    /**
     * Send request e voucher to parent
     */
    requestEVoucherToParent: function (event) {
      event.preventDefault()
      let sendFromIssuance = true
      this.$refs.requestEVoucherToParent.onShowModal(this.workpiece.id, sendFromIssuance)
    },
    /**
     * Transaction voucher success
     */
    onTransactionVoucherSuccess: function () {
      this.getEVoucherIssuanceDetail()
      this.getEVoucherInfoDistributedToAgent()
      this.getWorkpieceDetailList()
    },
    /**
     * Check is supplier
     */
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Check user has is agent
     */
    isAgent: function () {
      return functionUtils.isEntityType(EntityType.AGENT)
    },
    canUpdate: function () {
      if (functionUtils.isEntityType(EntityType.SUPPLIER)) {
        return this.hasAction('update_issuance') && !this.isAccountant() && !this.workpiece.hasDistributed && !this.isEntityDisabled()
      }
      return this.isAdmin()
    },
    /**
    * Show modal assign voucher
    */
    onShowModalAssignVoucher: function () {
      this.$refs.assignVoucherModal.onShowModal([], this.workpiece.id)
    },
    onShowModalAssignVoucherWithWorkpiece: function (item) {
      this.$refs.assignVoucherModal.onShowModal([], item.workpieceId, null, null, item.issuanceId)
    },
    /**
     * Get workpiece detail list
     */
    getWorkpieceDetailList: function () {
      let filter = {
        params: {
          workpieceId: this.workpiece.id,
          page: this.workpieceListPaginate.currentPage
        }
      }
      this.isLoading = true
      this.GET_WORKPIECE_DETAIL_LIST(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Get e voucher workpiece detail
     */
    getEVoucherIssuanceDetail: function () {
      this.indexRetrievingAddess = -1
      this.GET_VOUCHER_ISSUANCE_DETAIL({ id: this.workpiece.id })
    },
    doRetrievingFullAddress: function () {
      if (this.indexRetrievingAddess >= 0 && this.workpiece.usageSites && this.indexRetrievingAddess < this.workpiece.usageSites.length) {
        let addressFilter = {
          params: {
            countryCode: 84,
            cityCode: this.workpiece.usageSites[this.indexRetrievingAddess].province,
            districtCode: this.workpiece.usageSites[this.indexRetrievingAddess].district,
            wardCode: this.workpiece.usageSites[this.indexRetrievingAddess].ward
          }
        }
        this.GET_ADDRESS_DETAIL(addressFilter).then(
          function (res) {
            let fullAddress = functionUtils.concatFullAddress(res.data, this.workpiece.usageSites[this.indexRetrievingAddess].street)
            this.workpiece.usageSites[this.indexRetrievingAddess].fullAddress = fullAddress
            // get update next item if any
            this.indexRetrievingAddess++
            this.doRetrievingFullAddress()
          }.bind(this)
        )
      }
    },
    /**
     * Create e voucher success
     */
    onCreateEVoucherSuccess: function () {
      this.getEVoucherIssuanceDetail()
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    isAccountant: function () {
      return functionUtils.roleInEntity(EntityRoleType.ACCOUNTANT)
    },
    /**
     * Check has action
     */
    hasAction: function (metaAction) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaAction)
    },
    /**
     * Check entity type
     */
    isEntityType: function (entityType) {
      return functionUtils.isEntityType(entityType)
    },
    /**
     * Get e voucher info distributed to agent
     */
    getEVoucherInfoDistributedToAgent: function () {
      let filter = {
        params: {
          page: this.voucherInfoDistributedPaginate.currentPage,
          workpieceId: this.workpiece.id
        }
      }
      this.GET_VOUCHER_INFO_DISTRIBUTED_TO_AGENT(filter)
    },
    /**
     * Require value
     */
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    // Check value is greater than zero
    ruleMustGreaterThanZero (value) {
      return validationRules.ruleMustGreaterThan(value, 0)
    },
    /**
     * E Voucher type change
     */
    voucherIssuanceTypeChange: function () {
      this.workpiece.value = null
      this.workpiece.valueFormatter = null
    },
    /**
     * Format value
     */
    formatValue: function (value) {
      value = functionUtils.formatInteger(value && value.toString())
      if (this.workpiece.type === VoucherType.VOUCHER) {
        this.workpiece.valueFormatter = functionUtils.convertFormatNumberToMoney(
          value
        )
      } else {
        this.workpiece.valueFormatter = value
      }
      this.workpiece.value = value
    },
    /**
     * Format date
     */
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    /**
     * Parse date
     */
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Validate
     */
    validate: async function () {
      if (this.$refs.form.validate()) {
        this.usage_site.ids = functionUtils.convertToArrayId(this.usage_site.ids)
        let filter = {
          name: this.workpiece.name,
          code: this.workpiece.code,
          quantity: this.workpiece.quantity,
          status: this.workpiece.status,
          type: this.workpiece.type,
          value: this.workpiece.value,
          description: this.workpiece.description,
          siteIds: this.usage_site.ids,
          details: this.workpiece.details,
          issuanceUrl: this.issuanceImage,
          customerIds: this.customer.ids,
          conditionsOfUse: this.workpiece.conditionsOfUse,
          isPersistentCode: this.workpiece.isPersistentCode,
          isHidePrice: this.workpiece.isHidePrice,
          printTemplateId: this.workpiece.printTemplateId
        }
        await this.uploadIssuanceImage()
        filter['issuanceUrl'] = this.issuanceImage
        if (!functionUtils.isNull(this.workpiece.id)) {
          this.isLoading = true
          this.UPDATE_VOUCHER_ISSUANCE({
            id: this.workpiece.id,
            ...filter
          })
            .then(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.isLoading = false
              }.bind(this)
            )
            .catch(
              function (error) {
                this.isLoading = false
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    message:
                      this.$t(`${errorText}`) +
                      error.response.data.voucherList,
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    message: this.$t('login.somethingWentWrong'),
                    styleType: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        }
      }
    },
    updateDistributedVoucherIssuance: async function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      if (this.$refs.form.validate()) {
        this.usage_site.ids = functionUtils.convertToArrayId(this.usage_site.ids)
        let filter = {
          printTemplateId: this.workpiece.printTemplateId,
          isHidePrice: this.workpiece.isHidePrice,
          siteIds: this.usage_site.ids
        }
        if (!functionUtils.isNull(this.workpiece.id)) {
          this.isLoading = true
          this.UPDATE_DISTRIBUTED_VOUCHER_ISSUANCE({
            id: this.workpiece.id,
            ...filter
          })
            .then(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.isLoading = false
              }.bind(this)
            )
            .catch(
              function (error) {
                this.isLoading = false
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    message:
                      this.$t(`${errorText}`) +
                      error.response.data.voucherList,
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    message: this.$t('login.somethingWentWrong'),
                    styleType: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        }
      }
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      if (!this.isAdmin() && this.workpiece.hasDistributed) {
        this.updateDistributedVoucherIssuance()
      } else {
        this.validate()
      }
    },
    /**
     * Page change
     */
    onInfoDistributedPageChange: function (page) {
      this.voucherInfoDistributedPaginate.currentPage = page
      this.getEVoucherInfoDistributedToAgent()
    },
    /**
     * Page change
     */
    onWorkpieceListPageChange: function (page) {
      this.workpieceListPaginate.currentPage = page
      this.getWorkpieceDetailList()
    },
    /**
     * Show modal add voucher with type list
     */
    onShowModalAddEVoucher: function (createType) {
      let workpieceId = this.$route.params.id
      this.$refs.addEVoucherList.onShowModal(createType, workpieceId)
    },
    /**
     * Upload image
     */
    uploadIssuanceImage: async function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      if (this.imageFile !== null) {
        let formData = new FormData()
        formData.append('file', this.imageFile)
        formData.append('directory', 'eVoucher/Issuance')
        formData.append('forceOverride', true)
        await axios({
          method: 'POST',
          timeout: 6000,
          url: this.media_server.domain + '/api/v1/upload/',
          data: formData,
          headers: { Authorization: `JWT ${this.media_server.token}` }
        }).then(
          async function (res) {
            let data = res.data
            this.issuanceImage = data.domain + data.url
          }.bind(this)
        ).catch(
          async function () {
            this.issuanceImage = null
          }.bind(this)
        )
      }
    },
    /**
     * View history transaction
     */
    onViewHistoryTransaction: function (item) {
      this.$refs.workpieceHistoriesModal.onShowModal(item.issuanceId, item.workpieceId, item.quantityRemain)
    },
    /**
     * Show modal add list e voucher
     */
    onShowModalAddListEvoucher: function (createVoucherType, workpieceId, issuanceId) {
      this.$refs.addEVoucherList.onShowModal(createVoucherType, workpieceId, issuanceId)
    },
    onChangedTab: function ({ index, item }) {
      this.mainTabSelected = item.id
    },
    onShowModalExternExpiredDays: function (event, issuanceId) {
      event.preventDefault()
      this.$refs.extendExpiredDayModal.onShowModal(null, this.workpiece.id, issuanceId)
    },
    onClose: function () {
      window.history.back()
    },
    /**
     * Preview print voucher
     */
    onPreviewPrintVoucher: function () {
      let ownerData = this.workpiece.ownerData
      let voucherInfo = {
        supplierId: ownerData && ownerData.id,
        supplierName: ownerData && ownerData.name,
        supplierHotline: ownerData && ownerData.phone,
        supplierWebsite: ownerData && ownerData.website,
        name: this.workpiece.name,
        price: Number(this.workpiece.value),
        type: this.workpiece.type,
        isPersistentCode: this.workpiece.isPersistentCode,
        isHidePrice: this.workpiece.isHidePrice,
        expiredDate: moment().add(DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS, 'days').format('DD/MM/YYYY'),
        description: this.workpiece.description,
        conditionsOfUse: this.workpiece.conditionsOfUse,
        details: this.workpiece.details,
        logoSupplier: ownerData && ownerData.image_url,
        logoAgent: null
      }
      this.$refs.previewPrintVoucher.onShowModal(voucherInfo, this.workpiece.printTemplateId)
    },
    getTemplateConfigList: function () {
      this.isLoading = true
      this.printTemplateList = [{ value: null, text: '---' }]
      this.GET_PRINT_TEMPLATE_CONFIG_LIST().then(
        function (res) {
          let data = res.data
          data.forEach(e => {
            let item = {
              value: e.id,
              text: !functionUtils.isEmptyString(e.name) ? e.name : e.id
            }
            this.printTemplateList.push(item)
          })
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }
      )
    },
    onShowModalApprovalIssuance: function () {
      this.confirmModalTitle = this.$t('entity.notiApproveVoucherCreation')
      this.$refs.approvalIssuanceModal.onShowModal()
    },
    onConfirmApprovalIssuance: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.APPROVAL_VOUCHER_ISSUANCE({ id: this.workpiece.id }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getEVoucherIssuanceDetail()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_VOUCHER_ISSUANCE_DETAIL',
      'UPDATE_VOUCHER_ISSUANCE',
      'UPDATE_DISTRIBUTED_VOUCHER_ISSUANCE',
      'GET_VOUCHER_INFO_DISTRIBUTED_TO_AGENT',
      'GET_CHILDREN_ENTITY',
      'GET_CUSTOMER_LIST',
      'GET_TOKEN_MEDIA_SERVER',
      'GET_WORKPIECE_DETAIL_LIST',
      'GET_PRINT_TEMPLATE_CONFIG_LIST',
      'APPROVAL_VOUCHER_ISSUANCE',
      'GET_ADDRESS_DETAIL'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
